<template>
  <div class="container-fluid">
    <div
      class="page-header min-height-300 border-radius-xl mt-4"
      style="background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img src="@/assets/img/bruce-mars.jpg" alt="profile_image" class="shadow-sm w-100 border-radius-lg" />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ user.first_name }} {{ user.last_name }}</h5>
            <span class="d-flex"><p class="mb-0 font-weight-normal text-sm">Role: {{ user.role }}</p></span>
            <span class="d-flex"><small class="text-xs">Email: {{ user.email ? user.email :'-' }}</small></span>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div class="nav-wrapper position-relative end-0">
            <!-- <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
              <li class="nav-item">
                <a class="px-0 py-1 mb-0 nav-link active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                  <svg class="text-dark" width="16px" height="16px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path
                              class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                            />
                            <path
                              class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"
                            />
                            <path
                              class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">App</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                  <svg class="text-dark" width="16px" height="16px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>document</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path class="color-background" d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379" />
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Messages</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                  <svg class="text-dark" width="16px" height="16px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>settings</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-2020.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(304.000000, 151.000000)">
                            <polygon class="color-background" opacity="0.596981957" points="18.0883333 15.7316667 11.1783333 8.82166667 13.3333333 6.66666667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 8.82166667 11.1783333 15.315 17.6716667" />
                            <path
                              class="color-background"
                              d="M31.5666667,23.2333333 C31.0516667,23.2933333 30.53,23.3333333 30,23.3333333 C29.4916667,23.3333333 28.9866667,23.3033333 28.48,23.245 L22.4116667,30.7433333 L29.9416667,38.2733333 C32.2433333,40.575 35.9733333,40.575 38.275,38.2733333 L38.275,38.2733333 C40.5766667,35.9716667 40.5766667,32.2416667 38.275,29.94 L31.5666667,23.2333333 Z"
                              opacity="0.596981957"
                            />
                            <path
                              class="color-background"
                              d="M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.2366667,39.1133333 12.6033333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Settings</span>
                </a>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 row">
          <!-- <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4 position-relative">
            <profile-info-card
              title="Profile Information"
              description=""
              :info="{
                fullName: user.first_name+' '+user.last_name,
                email: user.email ? user.email :'-',
                mobile: user.phone ? user.phone:'-',
                location: user.country ? user.country:'-',
              }"
              :action="{
                route: 'javascript:;',
                tooltip: 'Edit Profile',
              }"
            />
            <hr class="vertical dark" />
          </div> -->
          <div class="col-12 col-md-6 col-xl-6 position-relative">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Profile Settings</h6>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group">
                  <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                    <div class="col-md-5 mx-auto bg-white new-pass p-2">
                      <label>First Name</label>
                      <input v-model="updateData.first_name" id="card-holder-name" type="text" class="bg-white w-100  mb-2">
                      <small class="err-txt text-danger error-txt" v-if='formValidation!=="" && formValidation["first_name"]!==""'>First Name is required</small>
                      <br>
                      <label>Last Name</label>
                      <input v-model="updateData.last_name" id="card-holder-name" type="text" class="bg-white w-100  mb-2">
                      <small class="err-txt text-danger error-txt" v-if='formValidation!=="" && formValidation["last_name"]!==""'>Last Name is required</small>
                      <br>
                      <label>Email</label>
                      <input v-model="updateData.email" id="card-holder-name" type="email" class="bg-white w-100  mb-2">
                      <small class="err-txt text-danger error-txt" v-if='formValidation!=="" && formValidation["email"]!==""'>Email is required</small>
                      <br>
                      <button @click="updateSettings()" style="font-size: 12px; background-color: #573078;" class="save-pass-btn p-2 mb-3 trips-btn  text-white fw-5 border-radius-lg">Update Settings </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <hr class="vertical dark" />
          </div>
          <div class="mt-4 col-12 col-xl-6 mt-xl-0">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Change Password</h6>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group">
                  <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                    <div class="col-md-5 mx-auto bg-white new-pass p-2">
                      <label>Old Password</label>
                      <input v-model="changePassword.old_password" id="card-holder-name" type="password" class="bg-white w-100  mb-2">
                      <small class="err-txt text-danger error-txt" v-if='passwordValidationForm!=="" && passwordValidationForm["old_password"]!==""'>Old Password is required</small>
                      <br>
                      <label>New Password</label>
                      <input v-model="changePassword.new_password" id="card-holder-name" type="password" class="bg-white w-100  mb-2">
                      <small class="err-txt text-danger error-txt" v-if='passwordValidationForm!=="" && passwordValidationForm["new_password"]!==""'>New Password is required</small>
                      <br>
                      <label>Confirm New Password</label>
                      <input v-model="changePassword.new_password_confirmation" id="card-holder-name" type="password" class="bg-white w-100  mb-2">
                      <small class="err-txt text-danger error-txt" v-if='passwordValidationForm!=="" && passwordValidationForm["new_password_confirmation"]!==""'>Confirm Password is required</small>
                      <br>
                      <button @click="changeUserPassword" style="font-size: 12px; background-color: #573078;" class="save-pass-btn p-2 mb-3 trips-btn  text-white fw-5 border-radius-lg"> Save </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mt-4">
          <div class="col-12">
            <div class="mb-5 ps-3">
              <h6 class="mb-1">Projects</h6>
              <p class="text-sm">Architects design houses</p>
            </div>
            <div class="row">
              <default-project-card
                title="Modern"
                :image="img1"
                label="Project #2"
                description="As Uber works through a huge amount of internal
                management turmoil."
                :authors="[
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                ]"
                :action="{
                  color: 'success',
                  label: 'View Project',
                }"
              />

              <default-project-card
                title="Scandinavian"
                :image="img2"
                label="Project #1"
                description="Music is something that every person has his or her own
                      specific opinion about."
                :authors="[
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'success',
                  label: 'View Project',
                }"
              />

              <default-project-card
                title="Minimalist"
                :image="img3"
                label="Project #3"
                description="Different people have different taste, and various types
                      of music."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                ]"
                :action="{
                  color: 'success',
                  label: 'View Project',
                }"
              />
              <default-project-card
                title="Gothic"
                image="https://images.unsplash.com/photo-1606744824163-985d376605aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
                label="Project #4"
                description="Why would anyone pick blue over pink? Pink is obviously a
                      better color."
                :authors="[
                  {
                    image: team4,
                    name: 'Peterson',
                  },
                  {
                    image: team3,
                    name: 'Nick Daniel',
                  },
                  {
                    image: team2,
                    name: 'Ryan Milly',
                  },
                  {
                    image: team1,
                    name: 'Elena Morison',
                  },
                ]"
                :action="{
                  color: 'success',
                  label: 'View Project',
                }"
              />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import ProfileInfoCard from './components/ProfileInfoCard.vue'
// import DefaultProjectCard from './components/DefaultProjectCard.vue'
// import MaterialSwitch from '@/components/MaterialSwitch.vue'
// import MaterialAvatar from '@/components/MaterialAvatar.vue'
import sophie from '@/assets/img/kal-visuals-square.jpg'
import marie from '@/assets/img/marie.jpg'
import ivana from '@/assets/img/ivana-square.jpg'
import peterson from '@/assets/img/team-4.jpg'
import nick from '@/assets/img/team-3.jpg'
import img1 from '@/assets/img/home-decor-1.jpg'
import img2 from '@/assets/img/home-decor-2.jpg'
import img3 from '@/assets/img/home-decor-3.jpg'
import team1 from '@/assets/img/team-1.jpg'
import team2 from '@/assets/img/team-2.jpg'
import team3 from '@/assets/img/team-3.jpg'
import team4 from '@/assets/img/team-4.jpg'

import setNavPills from '@/assets/js/nav-pills.js'
import setTooltip from '@/assets/js/tooltip.js'
import axiosClient from '../axios'
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'profile-overview',
  data() {
    return {
      updateData:'',
      passwordValidationForm:'',
      formValidation:'',
      user:'',
      changePassword:{
      'old_password':'',
      'new_password':'',
      'new_password_confirmation':''
      },
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
    }
  },
  components: {
    // ProfileInfoCard,
    // DefaultProjectCard,
    // MaterialSwitch,
    // MaterialAvatar,
  },

  mounted() {
    this.$store.state.isAbsolute = true
    setNavPills()
    setTooltip()
    this.getUser()
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false
  },
  methods:{
    getUser(){
    let user=localStorage.getItem('user')
    user= JSON.parse(user)
    this.user=user
    this.updateData=cloneDeep(this.user)
    if(this.user.role=='student' || this.user.role=='staff' || this.user.role=='parent'){
      this.$router.go(-1);
    }
   },
   //-----------CHANGE USER PASSWORD-------------
   async changeUserPassword(){
    if(this.validatePasswordForm()){
      return;
    }
    let user=localStorage.getItem('user')
    user= JSON.parse(user)
    let data={
      'user_id':user.id,
      'old_password':this.changePassword.old_password,
      'new_password':this.changePassword.new_password,
      'new_password_confirmation':this.changePassword.new_password_confirmation
      }
      if(data.new_password==data.old_password){
        this.snackbarMsg('New password can not be the same as old password!','error')
        return 
      }
      try {
        const response=await axiosClient.post('/changePassword',data)
        this.snackbarMsg('Password changed successfully!')
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        this.$router.push({ name: 'SignIn' })
        console.log(response)
      } catch (error) {
        console.log(error)
      }
   },
   snackbarMsg(message,type='success') {
      this.$snackbar.add({
        type: type,
        text: message,
        background: 'white',
      })
    },
    //------------VALIDATE FORM-------------
    validateForm(){
        let status=false
        let validate=''
        validate=cloneDeep(this.updateData)
        for(let item in this.updateData){
          if ((this.updateData[item] === '' || this.updateData[item] === undefined) && (item == "first_name" || item == "last_name" || item == "email")) {
              validate[item]="is required"
              status=true
          }else{
            validate[item]=''
          }
        }
        this.formValidation=validate
        return status;
      },
      async updateSettings(){
        if(this.validateForm()){
            return;
          }
          let data={
            'first_name':this.updateData.first_name,
            'last_name':this.updateData.last_name,
            'email':this.updateData.email,
          }
          try {
          const response=await axiosClient.post('/profileSettings',data)
          this.snackbarMsg('Profile Updated successfully!')
          this.updateUserData(data)
          console.log(response)
        } catch (error) {
          console.log(error)
        }
      },
      updateUserData(data){
        this.user.first_name=data.first_name
        this.user.last_name=data.last_name
        this.user.email=data.email

        let userData = localStorage.getItem('user');
        let user = JSON.parse(userData);
        user.first_name = data.first_name;
        user.last_name = data.last_name;
        user.email = data.email;
        localStorage.setItem('user', JSON.stringify(user));
        window.location.reload()
      },
      //------------VALIDATE PASSWORD-------------
      validatePasswordForm(){
        let status=false
        let validate=''
        validate=cloneDeep(this.changePassword)
        for(let item in this.changePassword){
          if ((this.changePassword[item] === '' || this.changePassword[item] === undefined)) {
              validate[item]="is required"
              status=true
          }else{
            validate[item]=''
          }
        }
        this.passwordValidationForm=validate
        return status;
      },
  }
}
</script>
<style>
.new-pass{
    height: auto;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: white;
    width: 385px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .save-pass-btn{
  margin-top: 4px;
  width: 10em !important; 
  height: 3em !important;
  }
  label{
    font-size: 11px !important;
  }
  #card-holder-name{
    font-size: 11px;
  }
  .err-txt{
    font-size: 10px;
  }
</style>
